import { makeAutoObservable, observable } from 'mobx';
import axios from 'axios';
import { API_BASE } from '../constants/api';

class AppStore {
    language = localStorage.getItem('language') ;
    constructor() {
        makeAutoObservable(this, {
            language:observable,
        }
        );
    }
    async sendContact(data) {
        try {
            const response = await axios.post(`${API_BASE}/mail/contact`, data);
            return { success: true, message: 'Message Sent Successfully' };
        } catch (error) {
            return { success: false, message: 'Message Not Sent' };
        }
    }
}

export default new AppStore()