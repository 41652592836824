import {useState, useRef, useEffect} from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import ScrollAnimation from "react-animate-on-scroll";
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18n from "i18next";  // i18next modülünü de dahil ediyoruz
import appStore from "../../store/appStore";

const HeaderOne = ({btnStyle, HeaderSTyle}) => {
    const { t } = useTranslation();
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    };
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`;
    const { clientHeight } = ref;
    const [language, setLanguage] = useState("");

    const changeLanguageHandler = (languageValue) => {
        localStorage.setItem("language", JSON.stringify(languageValue)); 
        appStore.language = languageValue;
        setLanguage(languageValue);
        i18n.changeLanguage(languageValue); 
    };

    useEffect(() => {
        const savedLang = localStorage.getItem("language");
        if (savedLang) {
            const lang = JSON.parse(savedLang);
            appStore.language = lang;
            setLanguage(lang);
            i18n.changeLanguage(lang);  
        }
    }, [i18n]);
    
    const checkChange = (value) => {
        setCheck(value);
    };

    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-3 col-md-6 col-4">
                            <Logo 
                                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo_dark.png`}
                            />
                        </div>
                        <div className="col-lg-9 col-md-6 col-8 position-static">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
    
                                <div className="header-btn">
                                    <a className={`btn-default ${btnStyle}`}  href="/propertyowner">For Property Owner</a>
                                </div>
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                
                                <Darkmode />

                                <div className="language-selector">
                                    <a 
                                        className={`lang-button ${appStore.language === 'en' ? 'active' : ''}`} 
                                        onClick={() => changeLanguageHandler('en')}
                                    >
                                        EN
                                    </a>
                                    <a 
                                        className={`lang-button ${appStore.language === 'tr' ? 'active' : ''}`} 
                                        onClick={() => changeLanguageHandler('tr')}
                                    >
                                        TR
                                    </a>
                                </div>

                            </div>  
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    );
};

export default HeaderOne;
